import { SVGAttributes, VFC } from "react"

export const Search: VFC<SVGAttributes<SVGElement>> = (props) => (
  <svg
    data-token-name="Search"
    width="1em"
    height="1em"
    {...props}
    viewBox="0 0 34 34"
  >
    <path
      d="M18.8708 18.8669C19.7745 17.9622 20.3333 16.713 20.3333 15.3333C20.3333 12.5719 18.0948 10.3333 15.3333 10.3333C12.5719 10.3333 10.3333 12.5719 10.3333 15.3333C10.3333 18.0948 12.5719 20.3333 15.3333 20.3333C16.715 20.3333 17.9658 19.7729 18.8708 18.8669ZM18.8708 18.8669L23.6667 23.6667M17 32C8.71573 32 2 25.2843 2 17C2 8.71573 8.71573 2 17 2C25.2843 2 32 8.71573 32 17C32 25.2843 25.2843 32 17 32Z"
      fill="none"
      stroke="currentColor"
      strokeWidth="2.5"
    />
  </svg>
)
