/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TaxDeclarationService {

    /**
     * @param beneficiaryId 
     * @param fiscalYear 
     * @param getFileName 
     * @returns any Success
     * @throws ApiError
     */
    public static getTaxDeclarationDocumentTaxDeclaration(
beneficiaryId: number,
fiscalYear: number,
getFileName?: boolean,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/taxdeclaration/taxdeclarationdocument/{beneficiaryId}/{fiscalYear}',
            path: {
                'beneficiaryId': beneficiaryId,
                'fiscalYear': fiscalYear,
            },
            query: {
                'getFileName': getFileName,
            },
            errors: {
                404: `Not Found`,
            },
        });
    }

    /**
     * @param beneficiaryId 
     * @returns any Success
     * @throws ApiError
     */
    public static getTaxDeclarationDocumentsTaxDeclaration(
beneficiaryId: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/taxdeclaration/taxdeclarationdocuments/{beneficiaryId}',
            path: {
                'beneficiaryId': beneficiaryId,
            },
            errors: {
                404: `Not Found`,
            },
        });
    }

}
