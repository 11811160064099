import {
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams,
} from "@mui/x-data-grid"

import { reEvaluateDate } from "../../helpers"
import styles from "../../Works.module.scss"
import { OverflowGridCellComponent } from "../OverflowGridCellComponent"
import { StatusButton } from "../StatusButton"
import { StatusText } from "../StatusText"
import { ColumnsProps } from "./models"

export const getGeneralColumns = (props: ColumnsProps): GridColDef[] => {
  const { tokenDnn, handleOpenWork, loadFile, handleRemoveDol, stateSnapshot } =
    props
  return [
    {
      field: "title",
      headerName: "Titre de l’œuvre",
      // minWidth: 200,
      flex: 2,
      headerClassName: styles["container-table-header"],
      cellClassName: styles["container-table-cell"],
      sortable: true,
      filterable: true,
      hideable: false,
      renderCell: (params) => (
        <>
          <OverflowGridCellComponent
            className={styles.withOnClick}
            value={params.value}
            onClick={() => handleOpenWork && handleOpenWork(params.row)}
          />
          <OverflowGridCellComponent
            className={styles.withoutOnClick}
            value={params.value}
          />
        </>
      ),
      // renderHeader: (params) => {
      //   return (
      //     <>
      //       <GridColumnHeaderTitle
      //         label={params.colDef.headerName || params.colDef.field}
      //         description={params.colDef.description}
      //         columnWidth={params.colDef.width || 200}
      //       />
      //       <div className="MuiDataGrid-iconButtonContainer">
      //         <StatusTooltip
      //           className="MuiDataGrid-sortIcon"
      //           title="Trier par date de déclaration"
      //           placement="top"
      //         >
      //           <IconButton className={styles["container-table-sort-icon"]}>
      //             <ExpandMoreIcon />
      //           </IconButton>
      //         </StatusTooltip>
      //       </div>
      //     </>
      //   )
      // },
    },
    {
      field: "declarationDate",
      headerName: "Déclarée le",
      headerClassName: styles["container-table-header"],
      maxWidth: 120,
      flex: 1,
      cellClassName: styles["container-table-cell"],
      sortable: true,
      filterable: true,
      valueFormatter: (params: GridValueFormatterParams) =>
        reEvaluateDate(params.value),
    },
    {
      field: "programTitle",
      headerName: "Titre de l’émission",
      headerClassName: styles["container-table-header"],
      // minWidth: 150,
      flex: 1.2,
      cellClassName: styles["container-table-cell"],
      sortable: true,
      filterable: true,
      renderCell: (params) => (
        <OverflowGridCellComponent value={params.value} />
      ),
    },
    {
      field: "exploitation",
      headerName: "Exploitant",
      headerClassName: styles["container-table-header"],
      // minWidth: 150,
      flex: 1,
      cellClassName: styles["container-table-cell"],
      sortable: true,
      filterable: true,
      renderCell: (params) => (
        <OverflowGridCellComponent value={params.value} />
      ),
    },
    {
      field: "broadcastDateBegin",
      headerName: "Début diffusion",
      headerClassName: styles["container-table-header"],
      maxWidth: 133,
      flex: 1,
      cellClassName: styles["container-table-cell"],
      sortable: true,
      filterable: true,
      valueFormatter: (params: GridValueFormatterParams) =>
        reEvaluateDate(params.value),
    },
    {
      field: "broadcastDateEnd",
      headerName: "Fin diffusion",
      headerClassName: styles["container-table-header"],
      maxWidth: 122,
      flex: 1,
      cellClassName: styles["container-table-cell"],
      sortable: true,
      filterable: true,
      valueFormatter: (params: GridValueFormatterParams) =>
        reEvaluateDate(params.value),
    },
    {
      field: "status",
      headerName: "Statut",
      headerClassName: styles["container-table-header"],
      cellClassName: styles["container-table-cell"],
      // minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <StatusText
          status={params.row.status}
          scamStatus={params.row.scamStatus ?? ""}
          // state={params.row.state}
        />
      ),
      sortable: false,
      filterable: false,
    },
    {
      field: "statusSelect",
      headerName: "",
      maxWidth: 120,
      flex: 1,
      headerClassName: styles["container-table-header"],
      cellClassName: styles["container-table-cell"],
      editable: false,
      renderCell: (params: GridRenderCellParams) => (
        <StatusButton
          row={params.row}
          tokenDnn={tokenDnn}
          loadFile={loadFile}
          handleRemoveDol={handleRemoveDol}
          stateSnapshot={stateSnapshot}
        />
      ),
      sortable: false,
      filterable: false,
    },
  ]
}
