/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum JournalistInformationContractType {
    CDI = 'Cdi',
    CDI_ON_ISSUE = 'CdiOnIssue',
    FREELANCE_CDD = 'FreelanceCdd',
    OCCASIONAL_CDD = 'OccasionalCdd',
    RETIREMENT = 'Retirement',
}
