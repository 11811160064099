/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ContentFrontDto } from '../models/ContentFrontDto';
import type { SendDolToCrmDto } from '../models/SendDolToCrmDto';
import type { SendUserPalToCrmDto } from '../models/SendUserPalToCrmDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AdminService {

    /**
     * @returns any Success
     * @throws ApiError
     */
    public static clearCacheAdmin(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/admin/clearcache',
        });
    }

    /**
     * @param cacheKey 
     * @returns any Success
     * @throws ApiError
     */
    public static deleteCahceEntryAdmin(
cacheKey: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/admin/cachekey/{cacheKey}',
            path: {
                'cacheKey': cacheKey,
            },
        });
    }

    /**
     * @param library 
     * @param documentType 
     * @param fileName 
     * @returns boolean Success
     * @throws ApiError
     */
    public static deleteSharepointDocumentAdmin(
library?: string,
documentType?: string,
fileName?: string,
): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/admin/deletesharepointdocument',
            query: {
                'library': library,
                'documentType': documentType,
                'fileName': fileName,
            },
            errors: {
                404: `Not Found`,
            },
        });
    }

    /**
     * @returns string Success
     * @throws ApiError
     */
    public static getCacheKeysAdmin(): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/admin/cachekeys',
        });
    }

    /**
     * @param types 
     * @returns ContentFrontDto Success
     * @throws ApiError
     */
    public static getContentByTypesAdmin(
types?: Array<string>,
): CancelablePromise<Array<ContentFrontDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/admin/getcontentbytypes',
            query: {
                'types': types,
            },
            errors: {
                404: `Not Found`,
            },
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public static refreshCacheAdmin(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/admin/refreshcache',
        });
    }

    /**
     * @param requestBody 
     * @returns ContentFrontDto Success
     * @throws ApiError
     */
    public static saveContentAdmin(
requestBody?: ContentFrontDto,
): CancelablePromise<ContentFrontDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/admin/savecontent',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not Found`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns SendUserPalToCrmDto Success
     * @throws ApiError
     */
    public static sendUserPalsToCrmAdmin(
requestBody?: SendUserPalToCrmDto,
): CancelablePromise<SendUserPalToCrmDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/admin/senduserpalstocrm',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not Found`,
            },
        });
    }

    /**
     * @param userId 
     * @returns any Success
     * @throws ApiError
     */
    public static sendUserPalToCrmAdmin(
userId: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/admin/senduserpaltocrm/{userId}',
            path: {
                'userId': userId,
            },
            errors: {
                404: `Not Found`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns SendDolToCrmDto Success
     * @throws ApiError
     */
    public static sendWrittenWorksToCrmByDolIdsAdmin(
requestBody?: SendDolToCrmDto,
): CancelablePromise<SendDolToCrmDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/admin/sendwrittenworkstocrmbydolid',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not Found`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns SendDolToCrmDto Success
     * @throws ApiError
     */
    public static sendWrittenWorksToCrmByParentDolIdsAdmin(
requestBody?: SendDolToCrmDto,
): CancelablePromise<SendDolToCrmDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/admin/sendwrittenworkstocrmbyparentdolid',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not Found`,
            },
        });
    }

    /**
     * @param dolId 
     * @returns any Success
     * @throws ApiError
     */
    public static sendWrittenWorkToCrmByDolIdAdmin(
dolId: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/admin/sendwrittenworktocrmbydolid/{dolId}',
            path: {
                'dolId': dolId,
            },
            errors: {
                404: `Not Found`,
            },
        });
    }

    /**
     * @param parentDolId 
     * @returns any Success
     * @throws ApiError
     */
    public static sendWrittenWorkToCrmByParentDolIdAdmin(
parentDolId: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/admin/sendwrittenworktocrmbyparentdolid/{parentDolId}',
            path: {
                'parentDolId': parentDolId,
            },
            errors: {
                404: `Not Found`,
            },
        });
    }

    /**
     * @param library 
     * @param documentType 
     * @param creationDate 
     * @param culture 
     * @param fileName 
     * @param fiscalYear 
     * @param title 
     * @param number 
     * @param formData 
     * @returns boolean Success
     * @throws ApiError
     */
    public static uploadSharepointDocumentAdmin(
library?: string,
documentType?: string,
creationDate?: string,
culture?: boolean,
fileName?: string,
fiscalYear?: number,
title?: string,
number?: number,
formData?: {
file: Blob;
},
): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/admin/uploadsharepointdocument',
            query: {
                'library': library,
                'documentType': documentType,
                'CreationDate': creationDate,
                'Culture': culture,
                'FileName': fileName,
                'FiscalYear': fiscalYear,
                'Title': title,
                'Number': number,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                404: `Not Found`,
            },
        });
    }

}
