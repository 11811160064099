import { SVGAttributes, VFC } from "react"

export const DownloadFile: VFC<SVGAttributes<SVGElement>> = (props) => (
  <svg
    style={{ borderRadius: "50%", border: "1px solid #6C606C" }}
    width="1em"
    height="1em"
    viewBox="0 0 40 40"
    fill="#6C606C"
    {...props}
  >
    {" "}
    <path d="M8.33398 30.0004V19.875H9.88456V28.3408H30.1167V19.875H31.6673V30.0004H8.33398Z" />{" "}
    <path d="M19.9412 23.1125L22.1118 19.876L24.8247 15.8093L27.3053 12.1577L24.6694 10L19.8636 18.0508L15.0577 10L12.4219 12.1577L19.9412 23.1125Z" />{" "}
  </svg>
)
