/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmailDocumentFrontDto } from '../models/EmailDocumentFrontDto';
import type { EmailFrontDto } from '../models/EmailFrontDto';
import type { EmailsFrontDto } from '../models/EmailsFrontDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class EmailsService {

    /**
     * @param formData 
     * @returns EmailFrontDto Created
     * @throws ApiError
     */
    public static createEmailEmails(
formData?: {
Body?: string;
CreationDate?: string;
Documents?: Array<EmailDocumentFrontDto>;
Files?: Array<Blob>;
From?: string;
HasAttachment?: boolean;
IsCrmMessage?: boolean;
IsRead?: boolean;
IsSentByUser?: boolean;
MessageId?: number;
Object?: string;
PriorityId?: number;
PriorityLabel?: string;
},
): CancelablePromise<EmailFrontDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/emails/sendemail',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Client Error`,
            },
        });
    }

    /**
     * @param documentId 
     * @param isCrmMessage 
     * @returns EmailFrontDto Success
     * @throws ApiError
     */
    public static getAttachmentEmails(
documentId: string,
isCrmMessage?: boolean,
): CancelablePromise<EmailFrontDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/emails/attachments/{documentId}',
            path: {
                'documentId': documentId,
            },
            query: {
                'isCrmMessage': isCrmMessage,
            },
            errors: {
                404: `Not Found`,
            },
        });
    }

    /**
     * @param messageId 
     * @returns EmailFrontDto Success
     * @throws ApiError
     */
    public static getEmailEmails(
messageId: string,
): CancelablePromise<EmailFrontDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/emails/messages/{messageId}',
            path: {
                'messageId': messageId,
            },
            errors: {
                404: `Not Found`,
            },
        });
    }

    /**
     * @returns EmailsFrontDto Success
     * @throws ApiError
     */
    public static getEmailsEmails(): CancelablePromise<EmailsFrontDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/emails/messages',
            errors: {
                404: `Not Found`,
            },
        });
    }

}
