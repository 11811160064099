/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EventsDataDto } from '../models/EventsDataDto';
import type { FaqDataDto } from '../models/FaqDataDto';
import type { LivesDataDto } from '../models/LivesDataDto';
import type { NewsDataDto } from '../models/NewsDataDto';
import type { SingleNewsDataDto } from '../models/SingleNewsDataDto';
import type { SloganDataDto } from '../models/SloganDataDto';
import type { TagsDataDto } from '../models/TagsDataDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AddvisoService {

    /**
     * @param limit 
     * @param perPage 
     * @param sitePublication 
     * @param fields 
     * @param order 
     * @param orderby 
     * @returns EventsDataDto Success
     * @throws ApiError
     */
    public static getEventsAddviso(
limit?: number,
perPage?: number,
sitePublication?: number,
fields?: string,
order?: string,
orderby?: string,
): CancelablePromise<Array<EventsDataDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/addviso/events',
            query: {
                'limit': limit,
                'per_page': perPage,
                'site_publication': sitePublication,
                '_fields': fields,
                'order': order,
                'orderby': orderby,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @param eventsId 
     * @returns EventsDataDto Success
     * @throws ApiError
     */
    public static getEventsByIdAddviso(
eventsId: number,
): CancelablePromise<EventsDataDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/addviso/events/{eventsId}',
            path: {
                'eventsId': eventsId,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @returns FaqDataDto Success
     * @throws ApiError
     */
    public static getFaqAddviso(): CancelablePromise<Array<FaqDataDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/addviso/faq',
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @param faqId 
     * @returns FaqDataDto Success
     * @throws ApiError
     */
    public static getFaqByIdAddviso(
faqId: number,
): CancelablePromise<FaqDataDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/addviso/faq/{faqId}',
            path: {
                'faqId': faqId,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @param perPage 
     * @param page 
     * @param sticky 
     * @param tags 
     * @param entitledPerson 
     * @param categories 
     * @param domaineCreation 
     * @param typePublication 
     * @param filter 
     * @param sitePublication 
     * @param order 
     * @param orderby 
     * @param fields 
     * @returns NewsDataDto Success
     * @throws ApiError
     */
    public static getNewsAddviso(
perPage?: number,
page?: number,
sticky?: string,
tags?: number,
entitledPerson?: number,
categories?: number,
domaineCreation?: number,
typePublication?: number,
filter?: boolean,
sitePublication?: string,
order?: string,
orderby?: string,
fields?: string,
): CancelablePromise<Array<NewsDataDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/addviso/news',
            query: {
                'per_page': perPage,
                'page': page,
                'sticky': sticky,
                'tags': tags,
                'entitled_person': entitledPerson,
                'categories': categories,
                'domaine_creation': domaineCreation,
                'type_publication': typePublication,
                'filter': filter,
                'site_publication': sitePublication,
                'order': order,
                'orderby': orderby,
                '_fields': fields,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @param newsId 
     * @param fields 
     * @param perPage 
     * @returns SingleNewsDataDto Success
     * @throws ApiError
     */
    public static getNewsByIdAddviso(
newsId: number,
fields?: string,
perPage?: number,
): CancelablePromise<SingleNewsDataDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/addviso/news/{newsId}',
            path: {
                'newsId': newsId,
            },
            query: {
                '_fields': fields,
                'per_page': perPage,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @param status 
     * @param sitePublications 
     * @returns LivesDataDto Success
     * @throws ApiError
     */
    public static getLivesAddviso(
status?: string,
sitePublications?: string,
): CancelablePromise<Array<LivesDataDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/addviso/lives',
            query: {
                'status': status,
                'sitePublications': sitePublications,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @returns SloganDataDto Success
     * @throws ApiError
     */
    public static getSloganAddviso(): CancelablePromise<SloganDataDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/addviso/slogan',
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @param tagName 
     * @returns TagsDataDto Success
     * @throws ApiError
     */
    public static getTagsAddviso(
tagName: string,
): CancelablePromise<Array<TagsDataDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/addviso/tags/{tagName}',
            path: {
                'tagName': tagName,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }

}
