/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum FunctionName {
    AUTHOR = 'Author',
    ILLUSTRATOR_DESIGNER = 'IllustratorDesigner',
    TRANSLATOR = 'Translator',
}
