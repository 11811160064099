import { STATUS_LABEL } from "../constants"
import { WorkStatus } from "../domain/api"
import { ScamStatus } from "../pages/Works/models"

export const getWorkStatusLabelValue = (
  status: WorkStatus,
  scamStatus: ScamStatus,
) => {
  const curStatus = STATUS_LABEL.find((i) => i.value === status)
  const label = scamStatus
    ? curStatus?.labels?.find((i) => i.scumStatuses.includes(scamStatus))
    : undefined
  return label ? label.value : curStatus?.defaultLabel ?? status
}

export const getWorkStatusColorValue = (
  status: WorkStatus,
  scamStatus: ScamStatus,
) => {
  const curStatus = STATUS_LABEL.find((i) => i.value === status)
  const color = scamStatus
    ? curStatus?.colors?.find((i) => i.scumStatuses.includes(scamStatus))
    : undefined
  return color ? color.value : curStatus?.defaultColor ?? "grey"
}

export const getWorkStatusOrderValue = (status: WorkStatus) => {
  const curStatus = STATUS_LABEL.find((i) => i.value === status)
  return curStatus ? curStatus.order : 100
}

export const getWorkStatusTooltipValue = (
  status: WorkStatus,
  scamStatus: ScamStatus,
) => {
  const curStatus = STATUS_LABEL.find((i) => i.value === status)
  const tooltip = scamStatus
    ? curStatus?.tooltips?.find((i) => i.scumStatuses.includes(scamStatus))
    : undefined
  return tooltip ? tooltip.value : undefined
}

export const getWorkStatusActionsValue = (
  status: WorkStatus,
  scamStatus: ScamStatus,
) => {
  const curStatus = STATUS_LABEL.find((i) => i.value === status)
  const actions = scamStatus
    ? curStatus?.actions?.find((i) => i.scumStatuses.includes(scamStatus))
    : undefined
  return actions ? actions.value : curStatus?.defaultActions ?? []
}
