export class LocalStorage {
  public static SetItem = (key: string, value: string) => {
    localStorage.setItem(key, value)
  }

  public static RemoveItem = (key: string) => {
    localStorage.removeItem(key)
  }

  public static SetBooleanItem = (key: string, value: boolean) => {
    localStorage.setItem(key, "" + value)
  }

  public static SetDateItem = (key: string, value: Date) => {
    localStorage.setItem(key, "" + value)
  }

  public static GetItem = (key: string): string | null => {
    return localStorage.getItem(key)
  }

  public static GetBooleanItem = (key: string): boolean | null => {
    const value = localStorage.getItem(key)
    if (value === null) return null

    return value === "true"
  }

  public static GetDateItem = (key: string): Date | null => {
    const value = localStorage.getItem(key)
    if (value === null) return null

    return new Date(value)
  }

  public static GetNumberItem = (key: string): number | null => {
    const value = localStorage.getItem(key)
    if (value === null) return null

    return Number(value)
  }
}
