import { SVGAttributes, VFC } from "react"

export const Audiovisual: VFC<SVGAttributes<SVGElement>> = (props) => (
  <svg
    data-token-name="Audiovisual"
    width="1em"
    height="1em"
    fill="currentColor"
    {...props}
    viewBox="0 0 16 12"
  >
    <path d="M15.3334 0H0.666748V10.6667H5.33341V12H10.6667V10.6667H15.3267L15.3334 0ZM14.0001 9.33333H2.00008V1.33333H14.0001V9.33333Z" />
  </svg>
)
