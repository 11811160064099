import { SVGAttributes, VFC } from "react"

export const AccountProfile: VFC<SVGAttributes<SVGElement>> = (props) => (
  <svg
    data-token-name="account-profile"
    width="1em"
    height="1em"
    {...props}
    viewBox="0 0 34 34"
  >
    <path
      d="M6.41245 27.6256C9.12539 30.3289 12.8675 32 17 32C21.1325 32 24.8746 30.3289 27.5876 27.6256M6.41245 27.6256C3.68679 24.9097 2 21.1518 2 17C2 8.71573 8.71573 2 17 2C25.2843 2 32 8.71573 32 17C32 21.1518 30.3132 24.9097 27.5876 27.6256M6.41245 27.6256C7.16385 26.0282 10.3333 22.8333 17 22.8333C23.6667 22.8333 26.8361 26.0282 27.5876 27.6256M21.1667 12.8333C21.1667 15.1345 19.3012 17 17 17C14.6988 17 12.8333 15.1345 12.8333 12.8333C12.8333 10.5321 14.6988 8.66667 17 8.66667C19.3012 8.66667 21.1667 10.5321 21.1667 12.8333Z"
      fill="none"
      stroke="currentColor"
      strokeWidth="2.5"
    />
  </svg>
)
