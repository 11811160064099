import "./index.scss"

import { ThemeProvider } from "@mui/material"
import React from "react"
import { createRoot } from "react-dom/client"

import theme from "./core/theme"
import UserContextProvider from "./UserContextProvider"

const App = React.lazy(() => import("./App"))

function AppWithCallbackAfterRender() {
  return (
    <React.Suspense fallback={<div>Chargement...</div>}>
      <ThemeProvider theme={theme}>
        <UserContextProvider children={<App />} />
      </ThemeProvider>
    </React.Suspense>
  )
}

const container = document.getElementById("root") as Element | DocumentFragment

const root = createRoot(container)
root.render(<AppWithCallbackAfterRender />)
