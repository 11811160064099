/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CopyrightFrontDto } from '../models/CopyrightFrontDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CopyrightsService {

    /**
     * @param beneficiaryId 
     * @returns CopyrightFrontDto Success
     * @throws ApiError
     */
    public static getCopyrightCopyrights(
beneficiaryId: number,
): CancelablePromise<CopyrightFrontDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/copyrights/{beneficiaryId}',
            path: {
                'beneficiaryId': beneficiaryId,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }

}
