/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum Repertory {
    AUDIOVISUAL = 'Audiovisual',
    JOURNALIST_AGREEMENT = 'JournalistAgreement',
    SONOROUS = 'Sonorous',
    STILL_IMAGE = 'StillImage',
    WRITTEN = 'Written',
}
