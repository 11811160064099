/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum NamesPseudonymType {
    MARITAL_NAME = 'MaritalName',
    OTHER = 'Other',
    PATRONYMIC_NAME = 'PatronymicName',
    PBE = 'Pbe',
    PSEUDONYM = 'Pseudonym',
    REGISTRATION_NAME = 'RegistrationName',
    USE_NAME = 'UseName',
}
