/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum BookGenre {
    ENCYCLOPEDIAS_AND_DICTIONARIES = 'EncyclopediasAndDictionaries',
    HISTORY_GENEALOGY_ART_HISTORY_AND_FINE_BOOKS = 'HistoryGenealogyArtHistoryAndFineBooks',
    HUMAN_SOCIAL_ECONOMIC_SCIENCES_AND_LAW = 'HumanSocialEconomicSciencesAndLaw',
    NOVELS_SHORT_STORIES_AND_ESSAYS = 'NovelsShortStoriesAndEssays',
    OTHER = 'Other',
    POETRY_AND_HAIKU = 'PoetryAndHaiku',
    PRACTICAL_GUIDES = 'PracticalGuides',
    RELIGION_SPIRITUALITY_AND_ESOTERICISM = 'ReligionSpiritualityAndEsotericism',
    SCHOOL_TEXTS = 'SchoolTexts',
    TECHNICAL_SCIENCES_AND_MEDICINE = 'TechnicalSciencesAndMedicine',
    THEATRE = 'Theatre',
    YOUNG = 'Young',
}
