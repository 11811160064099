/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ContactPhoneNumberType {
    CALLBACK_NUMBER = 'CallbackNumber',
    CELL_PHONE_NUMBER = 'CellPhoneNumber',
    FAX = 'Fax',
    NUMBER_ASSOCIATED_WITH_AN_ADDRESS = 'NumberAssociatedWithAnAddress',
    OTHER = 'Other',
    PERSONAL_NUMBER = 'PersonalNumber',
    PROFESSIONAL_NUMBER = 'ProfessionalNumber',
}
