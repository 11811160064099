import { SVGAttributes, VFC } from "react"

export const Written: VFC<SVGAttributes<SVGElement>> = (props) => (
  <svg
    data-token-name="Written"
    width="1em"
    height="1em"
    fill="currentColor"
    {...props}
    viewBox="0 0 16 12"
  >
    <path d="M14.0001 0.333333C13.2601 0.1 12.4467 0 11.6667 0C10.3667 0 8.96675 0.266667 8.00008 1C7.03341 0.266667 5.63341 0 4.33341 0C3.03341 0 1.63341 0.266667 0.666748 1V11.3333C1.63341 10.6 3.03341 10.3333 4.33341 10.3333C5.63341 10.3333 7.03341 10.6 8.00008 11.3333C8.96675 10.6 10.3667 10.3333 11.6667 10.3333C12.4467 10.3333 13.2601 10.4333 14.0001 10.6667C14.5001 10.8333 14.9334 11.0333 15.3334 11.3333V1C14.9334 0.7 14.5001 0.5 14.0001 0.333333ZM14.0001 9.33333C13.2667 9.1 12.4667 9 11.6667 9C10.5334 9 8.90008 9.43333 8.00008 10V2.33333C8.90008 1.76667 10.5334 1.33333 11.6667 1.33333C12.4667 1.33333 13.2667 1.43333 14.0001 1.66667V9.33333Z" />
  </svg>
)
