/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum BeneficiaryType {
    ASSIMILATED = 'Assimilated',
    ASSIMILATED_EDITOR = 'AssimilatedEditor',
    EDITOR = 'Editor',
    HEIR = 'Heir',
    MEMBER = 'Member',
    PRINCIPAL = 'Principal',
}
