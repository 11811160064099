import { WorkStatus } from "../domain/api"
import { ScamStatus } from "../pages/Works/models"

interface StringValueByScumStatus {
  scumStatuses: ScamStatus[]
  value: string
}

interface ActionsValueByScumStatus {
  scumStatuses: ScamStatus[]
  value: ActionProps[]
}

export interface StatusProps {
  value: WorkStatus
  defaultLabel: string
  labels?: StringValueByScumStatus[]
  order: number
  defaultColor: string
  colors?: StringValueByScumStatus[]
  defaultActions: ActionProps[]
  actions?: ActionsValueByScumStatus[]
  tooltips?: StringValueByScumStatus[]
  hasComplementActionWithCapability?: boolean
}

export interface ActionProps {
  label: string
  value: ActionsEnum
  url: string
}

export interface MenuModel {
  numero?: string
  label?: string
  path?: string
  opened?: boolean
  hasSubmenu?: boolean
  showForAllMembers: boolean
  showIsAnHeir: boolean
  showIsBe: boolean
  showOthersThanBe: boolean
  hiddenMenu?: boolean
  subMenus?: Array<MenuModel>
}

export enum ActionsEnum {
  CHECKOUT_DETAILS = "CheckOutDetails",
  EDIT = "Edit",
  DOWNLOAD_APPLICATION = "DownloadApplication",
  CREATE_DOL = "CreateDOL",
  CREATE_LIST = "CreateList",
  RENEW = "Renew",
  REMOVE = "Remove",
}
