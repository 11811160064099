/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ContactCivility {
    MR = 'Mr',
    MRS = 'Mrs',
    NONE = 'None',
}
