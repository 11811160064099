import { ReferenceCountryFrontDto } from "../domain/api"
import { ReferenceModel } from "../models"

export const sortCountry = (
  countries: Array<ReferenceCountryFrontDto>,
  value?: string,
): ReferenceModel[] => {
  const countryValueFrontDto = value
    ? countries.find((country) => country.value === value)
    : undefined

  const countryValue: ReferenceModel | undefined = countryValueFrontDto
    ? {
        label: countryValueFrontDto.label ?? "",
        value: countryValueFrontDto.value ?? "",
      }
    : undefined

  const sortedCountries = countries
    .filter((country) => country.value !== value)
    .sort((a, b) => {
      const la = a.label?.toLowerCase() ?? ""
      const lb = b.label?.toLowerCase() ?? ""
      if (la < lb) return -1
      if (la > lb) return 1
      return 0
    })
    .map((country) => {
      return {
        label: country.label ?? "",
        value: country.value ?? "",
      }
    })

  if (countryValue) sortedCountries.unshift(countryValue)

  return sortedCountries
}
