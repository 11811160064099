import { SVGAttributes, VFC } from "react"

export const DownloadFileDroit: VFC<SVGAttributes<SVGElement>> = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 12 13" fill="#6C606C" {...props}>
    {" "}
    <path
      d="M11.25 4.75H8.25V0.25H3.75V4.75H0.75L6 10L11.25 4.75ZM0.75 11.5V13H11.25V11.5H0.75Z"
      fill="#6C606C"
    />
  </svg>
)
