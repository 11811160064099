/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum MemberInformationCollege {
    AUDIOVISUAL = 'Audiovisual',
    RADIO = 'Radio',
    STILL_IMAGE = 'StillImage',
    WRITTEN = 'Written',
}
