/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ContentType {
    PAGE_LINK = 'PageLink',
    PAGE_CONTENT = 'PageContent',
    FILE_LINK = 'FileLink',
    FILE_GED = 'FileGed',
}
