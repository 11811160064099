import { SVGAttributes, VFC } from "react"

export const ArrowRight: VFC<SVGAttributes<SVGElement>> = (props) => (
  <svg
    data-token-name="ArrowRight"
    width="1em"
    height="1em"
    fill="currentColor"
    {...props}
    viewBox="0 0 16 16"
  >
    <path d="M8 0 6.59 1.41 12.17 7H0v2h12.17l-5.58 5.59L8 16l8-8-8-8Z" />
  </svg>
)
