import { SVGAttributes, VFC } from "react"

export const Book: VFC<SVGAttributes<SVGElement>> = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 22 18"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    data-token-name="Book"
    {...props}
  >
    <path d="M20 1C18.89 0.65 17.67 0.5 16.5 0.5C14.55 0.5 12.45 0.9 11 2C9.55 0.9 7.45 0.5 5.5 0.5C3.55 0.5 1.45 0.9 0 2V17.5C1.45 16.4 3.55 16 5.5 16C7.45 16 9.55 16.4 11 17.5C12.45 16.4 14.55 16 16.5 16C17.67 16 18.89 16.15 20 16.5C20.75 16.75 21.4 17.05 22 17.5V2C21.4 1.55 20.75 1.25 20 1ZM20 14.5C18.9 14.15 17.7 14 16.5 14C14.8 14 12.35 14.65 11 15.5V4C12.35 3.15 14.8 2.5 16.5 2.5C17.7 2.5 18.9 2.65 20 3V14.5Z" />
  </svg>
)
