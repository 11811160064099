import moment from "moment"

import { countryFrWithDom } from "../constants"
import { Country } from "./../domain/api/models/Country"

export const isValidIBAN = (value: string) => {
  // Remove spaces and to upper case
  const iban = value.replace(/ /g, "").toUpperCase()
  let ibancheckdigits = ""
  let leadingZeroes = true
  let cRest = ""
  let cOperator = ""
  let charAt
  let cChar
  let ibanregexp
  let i
  let p

  // Check for IBAN code length.
  // It contains:
  // country code ISO 3166-1 - two letters,
  // two check digits,
  // Basic Bank Account Number (BBAN) - up to 30 chars
  const minimalIBANlength = 5
  if (iban.length < minimalIBANlength) {
    return false
  }

  // Check the country code and find the country specific format
  const countrycode = iban.substring(0, 2)
  const bbancountrypatterns = {
    AL: "\\d{8}[\\dA-Z]{16}",
    AD: "\\d{8}[\\dA-Z]{12}",
    AT: "\\d{16}",
    AZ: "[\\dA-Z]{4}\\d{20}",
    BE: "\\d{12}",
    BH: "[A-Z]{4}[\\dA-Z]{14}",
    BA: "\\d{16}",
    BR: "\\d{23}[A-Z][\\dA-Z]",
    BG: "[A-Z]{4}\\d{6}[\\dA-Z]{8}",
    CR: "\\d{17}",
    HR: "\\d{17}",
    CY: "\\d{8}[\\dA-Z]{16}",
    CZ: "\\d{20}",
    DK: "\\d{14}",
    DO: "[A-Z]{4}\\d{20}",
    EE: "\\d{16}",
    FO: "\\d{14}",
    FI: "\\d{14}",
    FR: "\\d{10}[\\dA-Z]{11}\\d{2}",
    GE: "[\\dA-Z]{2}\\d{16}",
    DE: "\\d{18}",
    GI: "[A-Z]{4}[\\dA-Z]{15}",
    GR: "\\d{7}[\\dA-Z]{16}",
    GL: "\\d{14}",
    GT: "[\\dA-Z]{4}[\\dA-Z]{20}",
    HU: "\\d{24}",
    IS: "\\d{22}",
    IE: "[\\dA-Z]{4}\\d{14}",
    IL: "\\d{19}",
    IT: "[A-Z]\\d{10}[\\dA-Z]{12}",
    KZ: "\\d{3}[\\dA-Z]{13}",
    KW: "[A-Z]{4}[\\dA-Z]{22}",
    LV: "[A-Z]{4}[\\dA-Z]{13}",
    LB: "\\d{4}[\\dA-Z]{20}",
    LI: "\\d{5}[\\dA-Z]{12}",
    LT: "\\d{16}",
    LU: "\\d{3}[\\dA-Z]{13}",
    MK: "\\d{3}[\\dA-Z]{10}\\d{2}",
    MT: "[A-Z]{4}\\d{5}[\\dA-Z]{18}",
    MR: "\\d{23}",
    MU: "[A-Z]{4}\\d{19}[A-Z]{3}",
    MC: "\\d{10}[\\dA-Z]{11}\\d{2}",
    MD: "[\\dA-Z]{2}\\d{18}",
    ME: "\\d{18}",
    NL: "[A-Z]{4}\\d{10}",
    NO: "\\d{11}",
    PK: "[\\dA-Z]{4}\\d{16}",
    PS: "[\\dA-Z]{4}\\d{21}",
    PL: "\\d{24}",
    PT: "\\d{21}",
    RO: "[A-Z]{4}[\\dA-Z]{16}",
    SM: "[A-Z]\\d{10}[\\dA-Z]{12}",
    SA: "\\d{2}[\\dA-Z]{18}",
    RS: "\\d{18}",
    SK: "\\d{20}",
    SI: "\\d{15}",
    ES: "\\d{20}",
    SE: "\\d{20}",
    CH: "\\d{5}[\\dA-Z]{12}",
    TN: "\\d{20}",
    TR: "\\d{5}[\\dA-Z]{17}",
    AE: "\\d{3}\\d{16}",
    GB: "[A-Z]{4}\\d{14}",
    VG: "[\\dA-Z]{4}\\d{16}",
  }

  // @ts-ignore
  const bbanpattern = bbancountrypatterns[countrycode]

  // As new countries will start using IBAN in the
  // future, we only check if the countrycode is known.
  // This prevents false negatives, while almost all
  // false positives introduced by this, will be caught
  // by the checksum validation below anyway.
  // Strict checking should return FALSE for unknown
  // countries.
  if (typeof bbanpattern !== "undefined") {
    ibanregexp = new RegExp("^[A-Z]{2}\\d{2}" + bbanpattern + "$", "")
    if (!ibanregexp.test(iban)) {
      return false // Invalid country specific format
    }
  }

  // Now check the checksum, first convert to digits
  const ibancheck = iban.substring(4, iban.length) + iban.substring(0, 4)
  for (i = 0; i < ibancheck.length; i++) {
    charAt = ibancheck.charAt(i)
    if (charAt !== "0") {
      leadingZeroes = false
    }
    if (!leadingZeroes) {
      ibancheckdigits += "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ".indexOf(charAt)
    }
  }

  // Calculate the result of: ibancheckdigits % 97
  for (p = 0; p < ibancheckdigits.length; p++) {
    cChar = ibancheckdigits.charAt(p)
    cOperator = "" + cRest + "" + cChar
    // @ts-ignore
    cRest = cOperator % 97
  }

  // @ts-ignore
  return cRest === 1
}

export const isValidSocialWelfareNumber = (value: string) => {
  if (!value) return false

  const pattern = /^([1-4]|[7-8])[0-9]{4}(2[AB]|[0-9]{2})[0-9]{8}$/

  if (!pattern.test(value)) return false

  let number = parseInt(
    value.substring(0, 13).replace(/A/gi, "0").replace(/B/gi, "0"),
  )

  if (value.includes("A")) number = number - 1000000
  if (value.includes("B")) number = number - 2000000

  const key = parseInt(value.substring(13, 15))

  let result = number % 97

  result = 97 - result

  if (result !== key) return false

  return true
}

export const isValidSSWithBirthday = (
  value: string,
  birthday: string | Date,
  country: string,
): "empty" | "valid" | "invalid" | "birthdayError" => {
  if (!value) return "empty"
  if (!birthday) return "birthdayError"

  let status: "empty" | "valid" | "invalid" | "birthdayError" = "invalid"

  status = isValidSocialWelfareNumber(value) ? "valid" : "invalid"

  const birthdayStr =
    typeof birthday === "string" ? birthday : birthday.toUTCString()
  const now = moment()
  const br = moment(birthdayStr)

  const year = br.format("YY")
  const month = br.format("MM")

  if (
    ["1", "2"].includes(value.charAt(0)) &&
    countryFrWithDom.includes(country as Country) &&
    now.diff(br, "years") >= 18 &&
    !(year === value.substring(1, 3) && month === value.substring(3, 5))
  )
    status = "birthdayError"

  return status
}
