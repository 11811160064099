import { SVGAttributes, VFC } from "react"

export const Trash: VFC<SVGAttributes<SVGElement>> = (props) => (
  <svg
    data-token-name="Trash"
    width="1em"
    height="1em"
    fill="currentColor"
    {...props}
    viewBox="0 0 20 24"
  >
    <path d="M15.3332 8V21.3333H4.6665V8H15.3332ZM13.3332 0H6.6665L5.33317 1.33333H0.666504V4H19.3332V1.33333H14.6665L13.3332 0ZM17.9998 5.33333H1.99984V21.3333C1.99984 22.8 3.19984 24 4.6665 24H15.3332C16.7998 24 17.9998 22.8 17.9998 21.3333V5.33333Z" />
  </svg>
)
