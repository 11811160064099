import { InfoBubble } from "../../../components/InfoBubble/InfoBuble"
import { WorkStatus } from "../../../domain/api"
import {
  getWorkStatusColorValue,
  getWorkStatusLabelValue,
  getWorkStatusTooltipValue,
} from "../../../helpers/workInfo"
import { ScamStatus } from "../models"
import styles from "../Works.module.scss"
import { OverflowGridCellComponent } from "./OverflowGridCellComponent"

interface StatusTextProps {
  status: WorkStatus
  scamStatus: ScamStatus
  // state: string
}

export const StatusText = (props: StatusTextProps) => {
  const { status, scamStatus } = props

  const tooltip = getWorkStatusTooltipValue(status, scamStatus)

  return (
    <>
      <OverflowGridCellComponent
        value={getWorkStatusLabelValue(status, scamStatus)}
        className={
          styles[
            `container-table-cell-${getWorkStatusColorValue(
              status,
              scamStatus,
            )}`
          ]
        }
      />
      {tooltip && <InfoBubble description={tooltip} />}
    </>
  )
}
