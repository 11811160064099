import { SVGAttributes, VFC } from "react"

export const Linkedin: VFC<SVGAttributes<SVGElement>> = (props) => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 24 24"
    fill="#EB2764"
    xmlns="http://www.w3.org/2000/svg"
    data-token-name="Linkedin"
    {...props}
  >
    <path d="M7.170999999999999 5.009 A2.188 2.188 0 0 1 4.983 7.197000000000001 A2.188 2.188 0 0 1 2.7949999999999995 5.009 A2.188 2.188 0 0 1 7.170999999999999 5.009 z" />
    <path d="M9.237 8.855v12.139h3.769v-6.003c0-1.584.298-3.118 2.262-3.118 1.937 0 1.961 1.811 1.961 3.218v5.904H21v-6.657c0-3.27-.704-5.783-4.526-5.783-1.835 0-3.065 1.007-3.568 1.96h-.051v-1.66H9.237zm-6.142 0H6.87v12.139H3.095z" />
  </svg>
)
