import { GridFilterModel, GridSortModel } from "@mui/x-data-grid"

import {
  DolType,
  MemberInformationCollege,
  WorkTableFrontDto,
} from "../../domain/api"
import { getGeneralDetails } from "../Work/components/WorkDetailsFields/generalWorkDetailsFields"
import { getGeneralColumns } from "./components/WorksTableColumns/generalWorksTableColumns"
import { getImageColumns } from "./components/WorksTableColumns/imageWorksTableColumns"
import { getWrittenColumns } from "./components/WorksTableColumns/writtenWorksTableColumns"

export interface ActionProps {
  action: string
  title: string
}

export enum ScamStatus {
  DELETED_WORK = "DeletedWork",
  DUPLICATE_WORK = "DuplicateWork",
  REFUSED_WORK = "RefusedWork",
  NON_COMPLIANT_FUNCTION = "NonCompliantFunction",
  NON_COMPLIANT_CONTRACT = "NonCompliantContract",
  WORK_IN_PROGRESS = "WorkInProgress",
  MISSING_CONTRACT = "MissingContract",
  MISSING_CO_AUTHOR_CONTRACT = "MissingCoAuthorContract",
  MISSING_AUTHOR_CONTRACT = "MissingAuthorContract",
  REFERENCED_WORK = "ReferencedWork",
}

export interface WorkFrontDtoExtended extends WorkTableFrontDto {
  order: number
}

export interface StateSnapshotProps {
  tabValue: number
  pagination: {
    pageSize: number
    page: number
  }
  forAllWorksPage: boolean
  currentRow?: number
  sortModel?: GridSortModel
  filterModel?: GridFilterModel
  records?: WorkFrontDtoExtended[]
  sections?: string[]
}

export const enum SectionType {
  AUDIOVISUAL = "Audiovisual",
  RADIO = "Radio",
  STILL_IMAGE = "StillImage",
  WRITTEN_BOOK = "WrittenBook",
  WRITTEN_PRESS = "WrittenPress",
}

export const SECTIONS = [
  {
    type: SectionType.AUDIOVISUAL,
    title: "Audiovisuel",
    headerTitle: "Historique de vos déclarations",
    repertory: MemberInformationCollege.AUDIOVISUAL,
    tableTemplate: getGeneralColumns,
    detailsTemplate: getGeneralDetails,
    getRecords: (data: WorkFrontDtoExtended[]) => data,
  },
  {
    type: SectionType.RADIO,
    title: "Sonore",
    headerTitle: "Historique de vos déclarations",
    repertory: MemberInformationCollege.RADIO,
    tableTemplate: getGeneralColumns,
    detailsTemplate: getGeneralDetails,
    getRecords: (data: WorkFrontDtoExtended[]) => data,
  },
  {
    type: SectionType.STILL_IMAGE,
    title: "Images fixes",
    headerTitle: "Historique de vos déclarations",
    repertory: MemberInformationCollege.STILL_IMAGE,
    tableTemplate: getImageColumns,
    detailsTemplate: getGeneralDetails,
    getRecords: (data: WorkFrontDtoExtended[]) => data,
  },
  {
    type: SectionType.WRITTEN_BOOK,
    title: "Écrit (Livre)",
    headerTitle: "Historique de vos déclarations en ligne",
    repertory: MemberInformationCollege.WRITTEN,
    dolType: DolType.WRITTEN_BOOK,
    editable: true,
    tableTemplate: getWrittenColumns,
    // detailsTemplate: getWrittenDetails,
    getRecords: (data: WorkFrontDtoExtended[]) => data,
  },
  {
    type: SectionType.WRITTEN_PRESS,
    title: "Écrit (Presse)",
    headerTitle: "Historique de vos déclarations en ligne",
    repertory: MemberInformationCollege.WRITTEN,
    dolType: DolType.WRITTEN_PRESS,
    editable: true,
    tableTemplate: getWrittenColumns,
    // detailsTemplate: getWrittenDetails,
    getRecords: (data: WorkFrontDtoExtended[]) => data,
  },
]
