import { SVGAttributes, VFC } from "react"

export const Facebook: VFC<SVGAttributes<SVGElement>> = (props) => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-token-name="Facebook"
    {...props}
  >
    <path
      fill="#EB2764"
      d="M9.198 21.5h4v-8.01h3.604l.396-3.98h-4V7.5a1 1 0 011-1h3v-4h-3a5 5 0 00-5 5v2.01h-2l-.396 3.98h2.396v8.01z"
    />
  </svg>
)
