import {
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams,
} from "@mui/x-data-grid"

import { WorkStatus } from "../../../../domain/api"
import { classNames } from "../../../../helpers"
import {
  getIsPaperAsString,
  reEvaluateDate,
  reEvaluateEditor,
} from "../../helpers"
import styles from "../../Works.module.scss"
import { OverflowGridCellComponent } from "../OverflowGridCellComponent"
import { StatusButton } from "../StatusButton"
import { StatusText } from "../StatusText"
import { ColumnsProps } from "./models"

export const getWrittenColumns = (props: ColumnsProps): GridColDef[] => {
  const {
    tokenDnn,
    section,
    tabName,
    handleOpenWork,
    loadFile,
    handleRemoveDol,
    stateSnapshot,
  } = props
  const headerNameWritten: string =
    section === "WrittenBook" ? "Titre de l’ouvrage" : "Titre de publication"

  return [
    {
      field: "title",
      headerName: headerNameWritten,
      // minWidth: 200,
      flex: 1,
      headerClassName: styles["container-table-header"],
      cellClassName: (params) =>
        classNames(
          styles["container-table-cell"],
          params.row.status !== WorkStatus.ON_GOIN_WORK
            ? styles["container-table-cellWritten"]
            : "",
        ) ?? "",
      sortable: true,
      filterable: true,
      hideable: false,
      renderCell: (params) => (
        <>
          <OverflowGridCellComponent
            className={styles.withOnClick}
            value={params.value}
            onClick={() => handleOpenWork && handleOpenWork(params.row)}
          />
          <OverflowGridCellComponent
            className={styles.withoutOnClick}
            value={params.value}
          />
        </>
      ),
    },
    // {
    //   field: "PressPartnerName",
    //   headerName: "Titre de l’ouvrage",
    //   // minWidth: 200,
    //   flex: 1,
    //   headerClassName: styles["container-table-header"],
    //   cellClassName: classNames(
    //     styles["container-table-cell"],
    //     styles["container-table-cellWritten"],
    //   ),
    //   sortable: true,
    //   filterable: true,
    //   hideable: false,
    //   renderCell: (params) => (
    //     <>
    //       <OverflowGridCellComponent
    //         className={styles.withOnClick}
    //         value={params.value}
    //         // onClick={() => handleOpenWork(params.row)}
    //       />
    //       <OverflowGridCellComponent
    //         className={styles.withoutOnClick}
    //         value={params.value}
    //       />
    //     </>
    //   ),
    // },
    {
      field: "declarationDate",
      headerName: "Déclarée le",
      headerClassName: styles["container-table-header"],
      // maxWidth: 120,
      flex: 1,
      cellClassName: styles["container-table-cell"],
      sortable: true,
      filterable: true,
      valueFormatter: (params: GridValueFormatterParams) =>
        reEvaluateDate(params.value),
    },
    {
      field: "year",
      headerName: "Année de publication",
      headerClassName: styles["container-table-header"],
      // minWidth: 150,
      flex: 1,
      cellClassName: styles["container-table-cell"],
      sortable: true,
      filterable: true,
      renderCell: (params) => (
        <OverflowGridCellComponent
          value={params.value !== 0 ? params.value : ""}
        />
      ),
    },
    {
      field:
        tabName === "WrittenBook" ? "publisherPartnerName" : "articlesCount",
      headerName: tabName === "WrittenBook" ? "Editeur" : "Nombre d'articles",
      headerClassName: styles["container-table-header"],
      // minWidth: 150,
      flex: 1,
      cellClassName: styles["container-table-cell"],
      sortable: true,
      filterable: true,
      renderCell: (params) => (
        <OverflowGridCellComponent
          value={
            params.row.isAutoEdition
              ? reEvaluateEditor(params.row)
              : params.value
          }
          className={styles["no-wrap-cell"]}
        />
      ),
    },
    {
      field: "isPaper",
      headerName: "1ère Exploitation",
      headerClassName: styles["container-table-header"],
      // maxWidth: 133,
      flex: 1,
      cellClassName: styles["container-table-cell"],
      sortable: true,
      filterable: true,
      renderCell: (params) => (
        <OverflowGridCellComponent value={getIsPaperAsString(params.value)} />
      ),
    },
    {
      field: "status",
      headerName: "Statut",
      headerClassName: styles["container-table-header"],
      cellClassName: styles["container-table-cell"],
      // minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <StatusText
          status={params.row.status}
          scamStatus={params.row.scamStatus ?? ""}
          // state={params.row.state}
        />
      ),
      sortable: false,
      filterable: false,
    },
    {
      field: "statusSelect",
      headerName: "",
      maxWidth: 120,
      flex: 1,
      headerClassName: styles["container-table-header"],
      cellClassName: styles["container-table-cell"],
      editable: false,
      renderCell: (params: GridRenderCellParams) => (
        <StatusButton
          row={params.row}
          tokenDnn={tokenDnn}
          loadFile={loadFile}
          stateSnapshot={stateSnapshot}
          handleRemoveDol={handleRemoveDol}
        />
      ),
      sortable: false,
      filterable: false,
    },
  ]
}
