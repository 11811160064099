import { SVGAttributes, VFC } from "react"

export const Menu: VFC<SVGAttributes<SVGElement>> = (props) => (
  <svg
    data-token-name="Menu"
    width="1em"
    height="1em"
    {...props}
    viewBox="0 0 24 16"
  >
    <path
      d="M0 16H24V13.3333H0V16ZM0 9.33333H24V6.66667H0V9.33333ZM0 0V2.66667H24V0H0Z"
      fill="currentColor"
    />
  </svg>
)
