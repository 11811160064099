import { SVGAttributes, VFC } from "react"

export const Sonorous: VFC<SVGAttributes<SVGElement>> = (props) => (
  <svg
    data-token-name="Sonorous"
    width="1em"
    height="1em"
    fill="currentColor"
    {...props}
    viewBox="0 0 12 13"
  >
    <path d="M0 12.332V6.33203C0 5.4987 0.158333 4.71814 0.475 3.99036C0.791667 3.26259 1.21944 2.62925 1.75833 2.09036C2.29722 1.55148 2.93056 1.1237 3.65833 0.807031C4.38611 0.490365 5.16667 0.332031 6 0.332031C6.83333 0.332031 7.61389 0.490365 8.34167 0.807031C9.06944 1.1237 9.70278 1.55148 10.2417 2.09036C10.7806 2.62925 11.2083 3.26259 11.525 3.99036C11.8417 4.71814 12 5.4987 12 6.33203V12.332H8.53333V7.3987H11V6.33203C11 4.94314 10.5139 3.76259 9.54167 2.79036C8.56944 1.81814 7.38889 1.33203 6 1.33203C4.61111 1.33203 3.43056 1.81814 2.45833 2.79036C1.48611 3.76259 1 4.94314 1 6.33203V7.3987H3.46667V12.332H0Z" />
  </svg>
)
