import { SVGAttributes, VFC } from "react"

export const Audiovisuel: VFC<SVGAttributes<SVGElement>> = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 22 18"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    data-token-name="Audiovisuel"
    {...props}
  >
    <path d="M22 0H0V16H7V18H15V16H21.99L22 0ZM20 14H2V2H20V14Z" />
  </svg>
)
