/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum CommPreferencesMessageType {
    AG_DOCUMENTS = 'AgDocuments',
    ASTERISK = 'Asterisk',
    CALL_FOR_APPLICATIONS_ALBERT_LONDON_PRIZE = 'CallForApplicationsAlbertLondonPrize',
    CALLS_FOR_APPLICATIONS = 'CallsForApplications',
    DUTY_STATEMENTS = 'DutyStatements',
    HOUSE_OF_AUTHORS_WORKSHOPS = 'HouseOfAuthorsWorkshops',
    INVITATIONS = 'Invitations',
    MONTHLY_ELECTRONICLETTER = 'MonthlyElectronicletter',
    SCAM_QUESTIONNAIRES_SURVEYS = 'ScamQuestionnairesSurveys',
    TAX_RETURN = 'TaxReturn',
    THE_QUARTERLY_NEWSLETTER = 'TheQuarterlyNewsletter',
    ZOOM = 'Zoom',
}
