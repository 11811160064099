/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CopyrightsSimulatorRequest } from '../models/CopyrightsSimulatorRequest';
import type { CopyrightsSimulatorResponse } from '../models/CopyrightsSimulatorResponse';
import type { DiffuserFrontDto } from '../models/DiffuserFrontDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CopyrightsSimulatorService {

    /**
     * @param requestBody 
     * @returns CopyrightsSimulatorResponse Success
     * @throws ApiError
     */
    public static calculateCopyrightsCopyrightsSimulator(
requestBody?: CopyrightsSimulatorRequest,
): CancelablePromise<CopyrightsSimulatorResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/copyrightssimulator/calculatecopyrights',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @param repertory 
     * @returns DiffuserFrontDto Success
     * @throws ApiError
     */
    public static getDiffusersCopyrightsSimulator(
repertory: string,
): CancelablePromise<Array<DiffuserFrontDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/copyrightssimulator/getdiffusers/{repertory}',
            path: {
                'repertory': repertory,
            },
            errors: {
                500: `Server Error`,
            },
        });
    }

}
