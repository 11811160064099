/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { WorkFrontDto } from '../models/WorkFrontDto';
import type { WorkTableFrontDto } from '../models/WorkTableFrontDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class WorksService {

    /**
     * @param beneficiaryId 
     * @returns any Success
     * @throws ApiError
     */
    public static getExcelWorkFileWorks(
beneficiaryId?: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/works/excelworksfile',
            query: {
                'BeneficiaryId': beneficiaryId,
            },
            errors: {
                404: `Not Found`,
            },
        });
    }

    /**
     * @param beneficiaryId 
     * @param numberOfWorks 
     * @returns WorkTableFrontDto Success
     * @throws ApiError
     */
    public static getLatestWorksWorks(
beneficiaryId?: number,
numberOfWorks?: number,
): CancelablePromise<Array<WorkTableFrontDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/works/latestworksdeclarations',
            query: {
                'BeneficiaryId': beneficiaryId,
                'NumberOfWorks': numberOfWorks,
            },
            errors: {
                404: `Not Found`,
            },
        });
    }

    /**
     * @param id 
     * @param beneficiaryId 
     * @returns WorkFrontDto Success
     * @throws ApiError
     */
    public static getWorkWorks(
id: number,
beneficiaryId?: number,
): CancelablePromise<WorkFrontDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/works/work/{id}',
            path: {
                'id': id,
            },
            query: {
                'beneficiaryId': beneficiaryId,
            },
            errors: {
                404: `Not Found`,
            },
        });
    }

    /**
     * @param beneficiaryId 
     * @returns WorkTableFrontDto Success
     * @throws ApiError
     */
    public static getWorksWorks(
beneficiaryId?: number,
): CancelablePromise<Array<WorkTableFrontDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/works',
            query: {
                'BeneficiaryId': beneficiaryId,
            },
            errors: {
                404: `Not Found`,
            },
        });
    }

    /**
     * @param beneficiaryId 
     * @param numberOfWorks 
     * @param onlyWorksToComplete 
     * @returns WorkTableFrontDto Success
     * @throws ApiError
     */
    public static getWorksDeclarationsToCompleteWorks(
beneficiaryId?: number,
numberOfWorks?: number,
onlyWorksToComplete?: boolean,
): CancelablePromise<Array<WorkTableFrontDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/works/worksdeclarationstocomplete',
            query: {
                'BeneficiaryId': beneficiaryId,
                'NumberOfWorks': numberOfWorks,
                'OnlyWorksToComplete': onlyWorksToComplete,
            },
            errors: {
                404: `Not Found`,
            },
        });
    }

}
