/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EnumerationData } from '../models/EnumerationData';
import type { ReferenceCountryFrontDto } from '../models/ReferenceCountryFrontDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ReferencesService {

    /**
     * @returns any Success
     * @throws ApiError
     */
    public static getBookCoverTypesReferences(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/references/bookcovertypes',
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public static getBookGenresReferences(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/references/bookgenres',
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public static getCompanyNamesReferences(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/references/companynames',
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public static getContractTypeReferences(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/references/contracttype',
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public static getCopyrightDistributionRepertoriesReferences(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/references/copyrightdistributionrepertories',
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @returns ReferenceCountryFrontDto Success
     * @throws ApiError
     */
    public static getCountriesReferences(): CancelablePromise<Array<ReferenceCountryFrontDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/references/countries',
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public static getEmployerNameReferences(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/references/employername',
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @returns EnumerationData Success
     * @throws ApiError
     */
    public static getEnumerationDataReferences(): CancelablePromise<EnumerationData> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/references/all',
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public static getFormatTypesReferences(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/references/formattypes',
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public static getFunctionNamesReferences(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/references/functionnames',
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public static getNationalitiesReferences(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/references/nationalities',
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public static getPressTypesReferences(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/references/presstypes',
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public static getRepertoriesReferences(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/references/repertories',
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public static getWorkTableStatesReferences(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/references/worktablestates',
            errors: {
                500: `Server Error`,
            },
        });
    }

}
