/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box } from "@mui/material"
import React from "react"

import { EcTooltip } from "../../../components/EcTooltip/EcTooltip"

interface OverflowGridCellProps {
  value: string
  className?: string
  onClick?: () => void
}
export const OverflowGridCellComponent = (props: OverflowGridCellProps) => {
  const { value, className, onClick } = props
  const textElementRef = React.useRef<any>()
  // Define state and function to update the value
  const [hoverStatus, setHover] = React.useState(false)

  const compareSize = () => {
    const compare =
      textElementRef.current.scrollWidth > textElementRef.current.clientWidth
    setHover(compare)
  }

  // compare once and add resize listener on "componentDidMount"
  React.useEffect(() => {
    compareSize()
    window.addEventListener("resize", compareSize)
  }, [])

  // remove resize listener again on "componentWillUnmount"
  React.useEffect(
    () => () => {
      window.removeEventListener("resize", compareSize)
    },
    [],
  )

  return (
    <EcTooltip
      title={`${value}`}
      onClickEnable={!hoverStatus}
      component={
        <Box
          className={className ?? ""}
          ref={textElementRef}
          component="div"
          sx={{ textOverflow: "ellipsis", overflow: "hidden" }}
          onClick={onClick}
        >
          {value}
        </Box>
      }
      disableHoverListener={!hoverStatus}
      placement="top"
    />
  )
}
