import { SVGAttributes, VFC } from "react"

export const InboxFilled: VFC<SVGAttributes<SVGElement>> = (props) => (
  <svg
    data-token-name="InboxFilled"
    width="1em"
    height="1em"
    fill="currentColor"
    {...props}
    viewBox="0 0 24 24"
  >
    <path d="M21.3333 0H2.65333C1.17333 0 0.0133333 1.18667 0.0133333 2.66667L0 21.3333C0 22.8 1.17333 24 2.65333 24H21.3333C22.8 24 24 22.8 24 21.3333V2.66667C24 1.18667 22.8 0 21.3333 0ZM21.3333 16H16C16 18.2133 14.2 20 12 20C9.8 20 8 18.2133 8 16H2.65333V2.66667H21.3333V16Z" />
  </svg>
)
