import { SVGAttributes, VFC } from "react"

export const File: VFC<SVGAttributes<SVGElement>> = (props) => (
  <svg
    data-token-name="File"
    width="1em"
    height="1em"
    fill="currentColor"
    {...props}
    viewBox="0 0 14 18"
  >
    <path d="M3.66683 12.3346H10.3335V14.0013H3.66683V12.3346ZM3.66683 9.0013H10.3335V10.668H3.66683V9.0013ZM8.66683 0.667969H2.00016C1.0835 0.667969 0.333496 1.41797 0.333496 2.33464V15.668C0.333496 16.5846 1.07516 17.3346 1.99183 17.3346H12.0002C12.9168 17.3346 13.6668 16.5846 13.6668 15.668V5.66797L8.66683 0.667969ZM12.0002 15.668H2.00016V2.33464H7.8335V6.5013H12.0002V15.668Z" />
  </svg>
)
