/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ProfileCommPreferencesMediaDto } from '../models/ProfileCommPreferencesMediaDto';
import type { ProfileContactAddressAndPhoneNumberMediaDto } from '../models/ProfileContactAddressAndPhoneNumberMediaDto';
import type { ProfileContactCivilityMediaDto } from '../models/ProfileContactCivilityMediaDto';
import type { ProfileContactWebSocialMediaDto } from '../models/ProfileContactWebSocialMediaDto';
import type { ProfileEcSocialAndTaxRegimeMediaDto } from '../models/ProfileEcSocialAndTaxRegimeMediaDto';
import type { UserFrontDto } from '../models/UserFrontDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ProfileService {

    /**
     * @param beneficiaryId 
     * @returns UserFrontDto Success
     * @throws ApiError
     */
    public static getUserProfile(
beneficiaryId?: number,
): CancelablePromise<UserFrontDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/profile',
            query: {
                'beneficiaryId': beneficiaryId,
            },
            errors: {
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns UserFrontDto Success
     * @throws ApiError
     */
    public static updateCivilityMediaProfile(
requestBody?: ProfileContactCivilityMediaDto,
): CancelablePromise<UserFrontDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/profile/updatecivilitymedia',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not Found`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns UserFrontDto Success
     * @throws ApiError
     */
    public static updateCommPreferencesMediaProfile(
requestBody?: ProfileCommPreferencesMediaDto,
): CancelablePromise<UserFrontDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/profile/updatecommpreferencesmedia',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not Found`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns UserFrontDto Success
     * @throws ApiError
     */
    public static updateProfileContactAddressAndPhoneMediaProfile(
requestBody?: ProfileContactAddressAndPhoneNumberMediaDto,
): CancelablePromise<UserFrontDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/profile/updateprofilecontactaddressandphonemedia',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not Found`,
            },
        });
    }

    /**
     * @returns UserFrontDto Success
     * @throws ApiError
     */
    public static updateProfilHomeVideoViewedFlagProfile(): CancelablePromise<UserFrontDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/profile/updateprofilhomevideoviewedflag',
            errors: {
                404: `Not Found`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns UserFrontDto Success
     * @throws ApiError
     */
    public static updateTaxProfileMediaProfile(
requestBody?: ProfileEcSocialAndTaxRegimeMediaDto,
): CancelablePromise<UserFrontDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/profile/updatetaxprofilemedia',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not Found`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns UserFrontDto Success
     * @throws ApiError
     */
    public static updateWebSocialMediaProfile(
requestBody?: ProfileContactWebSocialMediaDto,
): CancelablePromise<UserFrontDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/profile/updatewebsocialmedia',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not Found`,
            },
        });
    }

}
