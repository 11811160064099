/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AuthenticateMessage } from '../models/AuthenticateMessage';
import type { CreateAccountRequest } from '../models/CreateAccountRequest';
import type { GetPasswordRequest } from '../models/GetPasswordRequest';
import type { UpdatePasswordRequest } from '../models/UpdatePasswordRequest';
import type { UserProfileFrontDto } from '../models/UserProfileFrontDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class IdentityService {

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static createAccountIdentity(
requestBody?: CreateAccountRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/identity/createaccount',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static getPasswordIdentity(
requestBody?: GetPasswordRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/identity/getpassword',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static updatePasswordIdentity(
requestBody?: UpdatePasswordRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/identity/updatepassword',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param tokenDnn 
     * @param userIdDnn 
     * @returns UserProfileFrontDto Success
     * @throws ApiError
     */
    public static getUserStatusIdentity(
tokenDnn?: string,
userIdDnn?: number,
): CancelablePromise<UserProfileFrontDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/identity/getuserstatus',
            query: {
                'tokenDnn': tokenDnn,
                'userIdDnn': userIdDnn,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns UserProfileFrontDto Success
     * @throws ApiError
     */
    public static loginIdentity(
requestBody?: AuthenticateMessage,
): CancelablePromise<UserProfileFrontDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/identity/login',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not Found`,
            },
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public static logoutIdentity(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/identity/logout',
        });
    }

    /**
     * @param beneficiaryId 
     * @returns UserProfileFrontDto Success
     * @throws ApiError
     */
    public static refreshRoleClaimsAfterAccountChangeIdentity(
beneficiaryId?: number,
): CancelablePromise<UserProfileFrontDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/identity/refreshroleclaimsafteraccountchange',
            query: {
                'beneficiaryId': beneficiaryId,
            },
            errors: {
                404: `Not Found`,
            },
        });
    }

    /**
     * @returns UserProfileFrontDto Success
     * @throws ApiError
     */
    public static refreshRoleClaimsAfterPalValidationIdentity(): CancelablePromise<UserProfileFrontDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/identity/refreshroleclaimsafterpalvalidation',
            errors: {
                404: `Not Found`,
            },
        });
    }

}
