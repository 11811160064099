/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PalFrontDto } from '../models/PalFrontDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PalService {

    /**
     * @returns PalFrontDto Success
     * @throws ApiError
     */
    public static getPalPal(): CancelablePromise<PalFrontDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/pal',
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns PalFrontDto Success
     * @throws ApiError
     */
    public static savePalPal(
requestBody?: PalFrontDto,
): CancelablePromise<PalFrontDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/pal/savepal',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns PalFrontDto Success
     * @throws ApiError
     */
    public static savePalWithoutControlePal(
requestBody?: PalFrontDto,
): CancelablePromise<PalFrontDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/pal/savepalwithoutcontrole',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Server Error`,
            },
        });
    }

}
