/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum DolType {
    AUDIOVISUAL = 'Audiovisual',
    INTERACTIVE = 'Interactive',
    RADIO = 'Radio',
    STILL_IMAGE = 'StillImage',
    TRANSLAT_AUDIOVISUAL = 'TranslatAudiovisual',
    VIDEO_YOU_TUBE = 'VideoYouTube',
    WRITTEN_BOOK = 'WrittenBook',
    WRITTEN_PRESS = 'WrittenPress',
    TEXT = 'TEXT',
}
