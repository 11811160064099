import { Tooltip } from "@mui/material"
import React from "react"

import { classNames } from "../../helpers/classNames"
import styles from "./EcTooltip.module.scss"

export interface EcTooltipProps {
  title: string | JSX.Element
  component: JSX.Element
  disableHoverListener?: boolean
  placement:
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start"
    | "top"
    | undefined
  onClickEnable?: boolean
  className?: string
}

export const EcTooltip = (props: EcTooltipProps) => {
  const [showStatus, setShowStatus] = React.useState(false)

  const ref = React.useRef() as React.MutableRefObject<HTMLInputElement>

  React.useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleClick = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowStatus(false)
      }
    }

    document.addEventListener("click", handleClick)

    return () => {
      document.removeEventListener("click", handleClick)
    }
  }, [ref])

  return (
    <>
      {props.onClickEnable && (
        <div ref={ref} onClick={() => setShowStatus(!showStatus)}>
          <Tooltip
            arrow
            title={props.title}
            classes={{
              tooltip: styles.tooltip,
              tooltipPlacementTop: styles.tooltipPlacementTop,
              tooltipPlacementBottom: styles.tooltipPlacementBottom,
              arrow: styles.arrow,
            }}
            className={classNames(
              props.className,
              props.title ? styles.clickable : "",
            )}
            placement={props.placement}
            disableHoverListener={props.disableHoverListener}
            open={showStatus}
          >
            {props.component}
          </Tooltip>
        </div>
      )}
      {!props.onClickEnable && (
        <Tooltip
          arrow
          title={props.title}
          classes={{
            tooltip: styles.tooltip,
            arrow: styles.arrow,
          }}
          className={props.className}
          placement={props.placement}
          disableHoverListener={props.disableHoverListener}
        >
          {props.component}
        </Tooltip>
      )}
    </>
  )
}
