/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum WorkStatus {
    DELETION_IN_PROGRESS = 'DeletionInProgress',
    HISTORY = 'History',
    INTEGRATED_WORK = 'IntegratedWork',
    LIST_SIGNED_IN_PROGRESS_BY_DECLARER = 'ListSignedInProgressByDeclarer',
    LOADING_WORK = 'LoadingWork',
    ON_GOIN_WORK = 'OnGoinWork',
    REFERENCED_WORK = 'ReferencedWork',
    REFUSAL_DELETED_WORK = 'RefusalDeletedWork',
    REFUSE_BY_AUTHOR = 'RefuseByAuthor',
    SIGNED_IN_PROGRESS = 'SignedInProgress',
    VALIDATED_BY_AUTHOR = 'ValidatedByAuthor',
    WORK_IN_PROGRESS = 'WorkInProgress',
}
