/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PrivateBoardSummaryDocumentDto } from '../models/PrivateBoardSummaryDocumentDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class GedService {

    /**
     * @param beneficiaryId 
     * @param declarationCode 
     * @returns any Success
     * @throws ApiError
     */
    public static getDeclarationDocumentContentGed(
beneficiaryId: number,
declarationCode: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/ged/declarationdocumentcontent/{beneficiaryId}/{declarationCode}',
            path: {
                'beneficiaryId': beneficiaryId,
                'declarationCode': declarationCode,
            },
            errors: {
                404: `Not Found`,
            },
        });
    }

    /**
     * @param beneficiaryId 
     * @returns any Success
     * @throws ApiError
     */
    public static getDeclarationDocumentsGed(
beneficiaryId: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/ged/declarationdocuments/{beneficiaryId}',
            path: {
                'beneficiaryId': beneficiaryId,
            },
            errors: {
                404: `Not Found`,
            },
        });
    }

    /**
     * @param privateDocumentType 
     * @returns any Success
     * @throws ApiError
     */
    public static getLatestPrivateDocumentGed(
privateDocumentType: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/ged/latestprivatedocument/{privateDocumentType}',
            path: {
                'privateDocumentType': privateDocumentType,
            },
            errors: {
                404: `Not Found`,
            },
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public static getMembershipDocumentContentGed(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/ged/membershipdocumentcontent',
            errors: {
                404: `Not Found`,
            },
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public static getMembershipDocumentsGed(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/ged/membershipdocuments',
            errors: {
                404: `Not Found`,
            },
        });
    }

    /**
     * @param fiscalYear 
     * @param fileName 
     * @returns any Success
     * @throws ApiError
     */
    public static getPrivateBoardSummaryDocumentGed(
fiscalYear: number,
fileName: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/ged/privateboardsummarydocument/{fiscalYear}/{fileName}',
            path: {
                'fiscalYear': fiscalYear,
                'fileName': fileName,
            },
            errors: {
                404: `Not Found`,
            },
        });
    }

    /**
     * @returns PrivateBoardSummaryDocumentDto Success
     * @throws ApiError
     */
    public static getPrivateBoardSummaryDocumentsGed(): CancelablePromise<Array<PrivateBoardSummaryDocumentDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/ged/privateboardsummarydocuments',
            errors: {
                404: `Not Found`,
            },
        });
    }

    /**
     * @param privateDocumentType 
     * @param fileName 
     * @returns any Success
     * @throws ApiError
     */
    public static getPrivateDocumentGed(
privateDocumentType: string,
fileName: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/ged/privatedocument/{privateDocumentType}/{fileName}',
            path: {
                'privateDocumentType': privateDocumentType,
                'fileName': fileName,
            },
            errors: {
                404: `Not Found`,
            },
        });
    }

    /**
     * @param privateDocumentType 
     * @param distributionId 
     * @returns any Success
     * @throws ApiError
     */
    public static getPrivateDocumentByDistributionIdGed(
privateDocumentType: string,
distributionId: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/ged/privatedocumentbydistributionid/{privateDocumentType}/{distributionId}',
            path: {
                'privateDocumentType': privateDocumentType,
                'distributionId': distributionId,
            },
            errors: {
                404: `Not Found`,
            },
        });
    }

    /**
     * @param publicDocumentType 
     * @param fileName 
     * @returns any Success
     * @throws ApiError
     */
    public static getPublicDocumentGed(
publicDocumentType: string,
fileName: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/ged/publicdocument/{publicDocumentType}/{fileName}',
            path: {
                'publicDocumentType': publicDocumentType,
                'fileName': fileName,
            },
            errors: {
                404: `Not Found`,
            },
        });
    }

    /**
     * @param beneficiaryId 
     * @param distributionCode 
     * @returns any Success
     * @throws ApiError
     */
    public static getStatementOfBenefitsDocumentGed(
beneficiaryId: number,
distributionCode: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/ged/statementofbenefitsdocument/{beneficiaryId}/{distributionCode}',
            path: {
                'beneficiaryId': beneficiaryId,
                'distributionCode': distributionCode,
            },
            errors: {
                404: `Not Found`,
            },
        });
    }

    /**
     * @param privateDocumentType 
     * @returns any Success
     * @throws ApiError
     */
    public static getPrivateDocumentsByTypeGed(
privateDocumentType?: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/ged/privatedocumentsbytype',
            query: {
                'privateDocumentType': privateDocumentType,
            },
            errors: {
                404: `Not Found`,
            },
        });
    }

}
