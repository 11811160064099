import AppRegistrationOutlinedIcon from "@mui/icons-material/AppRegistrationOutlined"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp"
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined"
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined"
import ListOutlinedIcon from "@mui/icons-material/ListOutlined"
import ManageSearchOutlinedIcon from "@mui/icons-material/ManageSearchOutlined"
import {
  Box,
  Button,
  ClickAwayListener,
  MenuItem,
  MenuList,
  Popover,
} from "@mui/material"
import { useRef, useState } from "react"
import { useNavigate } from "react-router"

import { Trash } from "../../../components/Icons"
import { STATUS_ACTIONS_COMPLEMENTARY, STATUS_LABEL } from "../../../constants"
import {
  DolType,
  MemberInformationCollege,
  WorkFrontDto,
  WorkStatus,
} from "../../../domain/api"
import { getWorkStatusActionsValue } from "../../../helpers/workInfo"
import { ActionProps, ActionsEnum, StatusProps } from "../../../models/models"
import { getSection } from "../helpers"
import { ScamStatus, StateSnapshotProps } from "../models"
import styles from "../Works.module.scss"

interface StatusButtonProps {
  row: WorkFrontDto
  tokenDnn: string
  loadFile?: (id: number) => void
  handleRemoveDol: (
    workStatus: WorkStatus,
    dolId: number | undefined,
    tokenDnn: string,
  ) => void
  stateSnapshot?: StateSnapshotProps
}

interface MenuIconProps {
  action: string
}

const MenuIcon = (props: MenuIconProps) => {
  const { action } = props

  switch (action) {
    case "downloadApplication":
      return (
        <FileDownloadOutlinedIcon
          className={styles["container-table-context-menu-icon"]}
        />
      )
    case "CheckOutDetails":
      return (
        <ManageSearchOutlinedIcon
          className={styles["container-table-context-menu-icon"]}
        />
      )
    case "Edit":
      return (
        <AppRegistrationOutlinedIcon
          className={styles["container-table-context-menu-icon"]}
        />
      )
    case "CreateList":
      return (
        <ListOutlinedIcon
          className={styles["container-table-context-menu-icon"]}
        />
      )
    case "CreateDOL":
      return (
        <FlagOutlinedIcon
          className={styles["container-table-context-menu-icon"]}
        />
      )
    case "Renew":
      return (
        <ListOutlinedIcon
          className={styles["container-table-context-menu-icon"]}
        />
      )
    case "Remove":
      return (
        <Trash
          width="24px"
          className={styles["container-table-context-menu-icon"]}
        />
      )
    default:
      return (
        <FlagOutlinedIcon
          className={styles["container-table-context-menu-icon"]}
        />
      )
  }
}

export const StatusButton = (props: StatusButtonProps) => {
  const { row, stateSnapshot } = props
  const [open, setOpen] = useState(false)
  const anchorRef = useRef<HTMLButtonElement>(null)
  const currentStatus: StatusProps | undefined = STATUS_LABEL.find(
    (s) => s.value === row.status,
  )

  const getActions = (): Array<ActionProps> => {
    let lstActions = getWorkStatusActionsValue(
      row.status,
      row.scamStatus as ScamStatus,
    )
    if (
      row.repertory === MemberInformationCollege.WRITTEN ||
      row.repertory === MemberInformationCollege.STILL_IMAGE
    ) {
      if (row.status === WorkStatus.VALIDATED_BY_AUTHOR) {
        lstActions = lstActions.filter(
          (action) => action.value === ActionsEnum.DOWNLOAD_APPLICATION,
        )
      }
      if (row.status === WorkStatus.ON_GOIN_WORK) {
        lstActions = lstActions.filter(
          (action) => action.value === ActionsEnum.EDIT,
        )
      }
      lstActions = lstActions.filter(
        (action) => action.value !== ActionsEnum.CHECKOUT_DETAILS,
      )
    }
    if (currentStatus?.hasComplementActionWithCapability && row.capability) {
      const complementaryAction = STATUS_ACTIONS_COMPLEMENTARY.find(
        (s) => s.value === row.capability,
      )
      if (complementaryAction) return [...lstActions, complementaryAction]
    }
    if (!row.dolId) {
      lstActions = lstActions.filter(
        (s: { label: string }) =>
          s.label !== "Télécharger le PDF de la déclaration",
      )
    }
    // ODA Exclure la suppression pour les DOLS Youtube
    if (row.dolType === DolType.VIDEO_YOU_TUBE) {
      lstActions = lstActions.filter(
        (s: { value: string }) => s.value !== ActionsEnum.REMOVE,
      )
    }
    return lstActions
  }

  const actions = getActions()

  const navigate = useNavigate()

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return
    }

    setOpen(false)
  }

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Tab") {
      event.preventDefault()
      setOpen(false)
    } else if (event.key === "Escape") {
      setOpen(false)
    }
  }

  const handleClickMenu = (a: ActionProps) => {
    switch (a.value) {
      case ActionsEnum.CHECKOUT_DETAILS: {
        navigate(`/oeuvre/${row.workId}`, {
          state: {
            work: row,
            stateSnapshot: stateSnapshot,
          },
        })
        setOpen(false)
        break
      }
      case ActionsEnum.CREATE_DOL: {
        navigate("/declarer-oeuvre")
        setOpen(false)
        break
      }
      case ActionsEnum.CREATE_LIST: {
        const urlRedirection = `${
          process.env.REACT_APP_REDIRECT_DOL_EXTRANET as string
        }/ui/DesktopModules/Scam/Modules/Declaration/EcRedirect/EcDeclarationsRedirect.html?typeDol=${
          row.dolType
        }&declarationOri=${row.dolId}&tokenDnn=${props.tokenDnn}`

        window.open(urlRedirection, "_blank")
        setOpen(false)
        break
      }
      case ActionsEnum.RENEW: {
        const urlRedirection = `${
          process.env.REACT_APP_REDIRECT_DOL_EXTRANET as string
        }/ui/DesktopModules/Scam/Modules/Declaration/EcRedirect/EcDeclarationsRedirect.html?typeDol=${
          row.dolType
        }&declarationOri=${row.dolId}&tokenDnn=${props.tokenDnn}`

        window.open(urlRedirection, "_blank")
        setOpen(false)
        break
      }
      case ActionsEnum.EDIT: {
        const urlRedirection = getSection(row.repertory, row.dolType)?.editable
          ? `/declarer-oeuvre/${row.workId}`
          : `${
              process.env.REACT_APP_REDIRECT_DOL_EXTRANET as string
            }/ui/DesktopModules/Scam/Modules/Declaration/EcRedirect/EcDeclarationsRedirect.html?typeDol=${
              row.dolType
            }&declarationId=${row.dolId}&tokenDnn=${props.tokenDnn}&stepDol=${
              row.declarationStep
            }`
        getSection(row.repertory, row.dolType)?.editable
          ? navigate(urlRedirection)
          : window.open(urlRedirection, "_blank")
        setOpen(false)
        break
      }
      case ActionsEnum.REMOVE: {
        props.handleRemoveDol(row.status, row.dolId, props.tokenDnn)
        setOpen(false)
        break
      }
      case ActionsEnum.DOWNLOAD_APPLICATION: {
        props.loadFile &&
          props.loadFile(
            row.repertory === "Written"
              ? row.parentDolId ?? -1
              : row.dolPrincipalId ?? row.dolId ?? -1,
          )
        setOpen(false)
        break
      }
      default:
        setOpen(false)
        break
    }
  }
  return actions.length > 0 ? (
    <>
      <Button
        ref={anchorRef}
        id="composition-button"
        variant="outlined"
        size="medium"
        onClick={() => handleToggle()}
        endIcon={open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        className={styles["container-table-action-button"]}
      >
        <span className={styles["hide-nodesktop"]}>Action</span>
        <span className={styles["hide-desktop"]}>Sélectionnez</span>
      </Button>
      <Popover
        open={open}
        anchorEl={anchorRef.current}
        className={styles["container-table-popper"]}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <MenuList
            autoFocusItem={open}
            id="composition-menu"
            aria-labelledby="composition-button"
            onKeyDown={handleListKeyDown}
            className={styles["container-table-context-menu"]}
          >
            {actions.map((a: ActionProps, index: number) => {
              return (
                <MenuItem
                  key={`item-action-${index}`}
                  onClick={() => handleClickMenu(a)}
                  classes={{
                    root: styles["container-table-context-menu-item"],
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <MenuIcon action={a.value} />
                    {a.label}
                  </Box>
                </MenuItem>
              )
            })}
          </MenuList>
        </ClickAwayListener>
      </Popover>
    </>
  ) : (
    <></>
  )
}
