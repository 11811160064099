import { SVGAttributes, VFC } from "react"

export const SwitchActivated: VFC<SVGAttributes<SVGElement>> = (props) => (
  <svg
    data-token-name="SwitchActivated"
    width="1em"
    height="1em"
    fill="currentColor"
    {...props}
    viewBox="0 0 20 10"
  >
    <path d="M15 0H5C2.24 0 0 2.24 0 5C0 7.76 2.24 10 5 10H15C17.76 10 20 7.76 20 5C20 2.24 17.76 0 15 0ZM15 8C13.34 8 12 6.66 12 5C12 3.34 13.34 2 15 2C16.66 2 18 3.34 18 5C18 6.66 16.66 8 15 8Z" />
  </svg>
)
