import moment from "moment"
import React, { useEffect, useState } from "react"

import {
  LOCAL_STORAGE_EXPIRATION_MINUTES,
  LOCAL_STORAGE_USER,
} from "./constants"
import { ContactAyantDroitFrontDto, UserAccountDto } from "./domain/api"
import { LocalStorage } from "./helpers/LocalStorage"
import { UserProfilFrontDtoExtended, UserStore } from "./models"

export const UserContext = React.createContext<UserStore>({} as UserStore)

interface UserContextProviderProps {
  children: JSX.Element
}

const UserContextProvider = (props: UserContextProviderProps): JSX.Element => {
  const [state, setUser] = React.useState<UserProfilFrontDtoExtended>({
    email: "",
    user: {} as UserAccountDto,
    contact: {} as ContactAyantDroitFrontDto,
    currentBeneficiaryId: undefined,
    isScamAdmin: false,
    references: {
      countries: [],
      nationalities: [],
      repertories: [],
      companyNames: [],
      contractType: [],
      employerName: [],
    },
    expiration: moment(),
    redirectDocuSignExpiration: undefined,
  })
  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const dispatch = (obj: UserProfilFrontDtoExtended) => {
    setUser(obj)
    if (obj.user?.userIdDnn)
      LocalStorage.SetItem(LOCAL_STORAGE_USER, JSON.stringify(obj))
    else LocalStorage.RemoveItem(LOCAL_STORAGE_USER)
  }

  useEffect(() => {
    const user: UserProfilFrontDtoExtended | null = JSON.parse(
      LocalStorage.GetItem(LOCAL_STORAGE_USER)!,
    )

    const now = moment()
    if (user && now.isBefore(user.expiration)) {
      const newUser = {
        ...user,
        expiration: now.add(LOCAL_STORAGE_EXPIRATION_MINUTES, "minutes"),
      }
      setUser(newUser)
      LocalStorage.SetItem(LOCAL_STORAGE_USER, JSON.stringify(newUser))
    } else {
      localStorage.clear()
    }
    setIsLoaded(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <UserContext.Provider value={{ state, dispatch }}>
      {isLoaded && props.children}
    </UserContext.Provider>
  )
}

export default UserContextProvider
