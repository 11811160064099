/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum CommPreferencesMessageGroup {
    DEMATERIALIZATION_OF_STATEMENTS_OF_RIGHTS = 'DematerializationOfStatementsOfRights',
    ELECTRONIC_SENDING_OF_MEETING_NOTICES = 'ElectronicSendingOfMeetingNotices',
    EVENTS = 'Events',
    NEWSLETTERS = 'Newsletters',
}
