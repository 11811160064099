/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum DeclarationStep {
    ADAPTATIONS = 'Adaptations',
    AUTHORS_AND_PRODUCERS = 'AuthorsAndProducers',
    EXPLOITATIONS = 'Exploitations',
    INSERTS = 'Inserts',
    NONE = 'None',
    OEUVRE = 'Oeuvre',
    THEMATICS_AND_KEYWORDS = 'ThematicsAndKeywords',
    VALIDATED = 'Validated',
    VALIDATION = 'Validation',
}
