import { SVGAttributes, VFC } from "react"

export const ChevronLeft: VFC<SVGAttributes<SVGElement>> = (props) => (
  <svg
    data-token-name="ChevronLeft"
    width="1em"
    height="1em"
    fill="currentColor"
    {...props}
    viewBox="0 0 6 8"
  >
    <path d="M5.2735 0.94L4.3335 0L0.333496 4L4.3335 8L5.2735 7.06L2.22016 4L5.2735 0.94Z" />
  </svg>
)
