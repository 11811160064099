/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum BookCoverType {
    GENERAL_LITERATURE = 'GeneralLiterature',
    HIGHLY_ILLUSTRATED_BOOKS = 'HighlyIllustratedBooks',
    OTHER = 'Other',
    PAPERBACKS = 'Paperbacks',
    PRACTICAL_BOOKS = 'PracticalBooks',
    PRIMARY_SCHOOL_BOOKS = 'PrimarySchoolBooks',
    SCIENCE_AND_MEDICINE_PROFESSIONAL_BOOKS = 'ScienceAndMedicineProfessionalBooks',
    SECONDARY_SCHOOL_BOOKS = 'SecondarySchoolBooks',
    UNIVERSITY_AND_PROFESSIONAL_BOOKS = 'UniversityAndProfessionalBooks',
}
