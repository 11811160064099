/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ContactWebsiteSocialMediaType {
    FACEBOOK = 'Facebook',
    INSTAGRAM = 'Instagram',
    LINKED_IN = 'LinkedIn',
    OTHER = 'Other',
    TWITTER = 'Twitter',
    VIMEO = 'Vimeo',
    WEBSITE = 'Website',
    YOUTUBE = 'Youtube',
}
