/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ContactAddressType {
    BUSINESS_ADDRESS = 'BusinessAddress',
    HEIR_ADDRESS = 'HeirAddress',
    MINOR_CHILD_ADDRESS = 'MinorChildAddress',
    OTHER = 'Other',
    PRIVATE_ADDRESS = 'PrivateAddress',
    TAX_ADDRESS = 'TaxAddress',
    TEMPORARY_ADDRESS = 'TemporaryAddress',
}
