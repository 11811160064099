import { InfoOutlined } from "@mui/icons-material"
import {
  IconButton,
  styled,
  SxProps,
  Theme,
  Tooltip,
  tooltipClasses,
  TooltipProps,
} from "@mui/material"
import { useState } from "react"

interface InfoBubbleProps {
  description: string
  sx?: SxProps<Theme>
}

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#540352",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#540352",
    color: "#FFFFFF",
    fontFamily: "DM Sans",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "16px",
    textAlign: "center",
    padding: "8px 8px",
  },
}))

export const InfoBubble = (props: InfoBubbleProps) => {
  const { description, sx } = props

  const [open, setOpen] = useState(false)

  return (
    <StyledTooltip
      title={description}
      placement="top"
      open={open}
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
    >
      <IconButton sx={sx}>
        <InfoOutlined fontSize="small" />
      </IconButton>
    </StyledTooltip>
  )
}
